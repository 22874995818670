import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { slide } from "svelte/transition"
import isMobileVersion from "../src/shared/helpers/is_mobile_version"

// Connects to data-controller="side-banners"
export default class extends Controller {
  static targets = ["container", "mobileContainer"]

  connect() {
    if (this.isPropperEnviorment() && this.hasContainerTarget && Sti.isCurrentPageContentPage) {
      this.loadSideBanners()
    }
  }

  isPropperEnviorment() { return Sti.env !== 'development' }

  async loadSideBanners() {
    fetch('/side_banners')
      .then(response => response.text())
      .then(html => {
        if (isMobileVersion()) {
          this.mobileContainerTarget.innerHTML = html
          this.mobileContainerTarget.querySelectorAll('.side-banners').forEach(group => {
            new SideBannersGroup(group)
          })
        } else {
          this.containerTarget.innerHTML = html
        }
      })
  }
}

class SideBannersGroup {
  constructor(container) {
    this.container = container
    this.slides = this.container.querySelectorAll('.side-banner')
    this.dots = this.container.querySelectorAll('.dot')

    this.imagesLoadedCounter = 0
    this.images = this.container.querySelectorAll('img')

    this.dots.forEach(dot => {
      dot.addEventListener('click', (e) => this.dotClicked(e))
    })

    this.images.forEach(image => {
      image.addEventListener('load', (_) => this.imageLoaded())
    })
  }

  dotClicked(e) {
    e.preventDefault()
    const currentDot = e.target
    this.dots.forEach(dot => dot.classList.remove('active'))
    currentDot.classList.add('active')

    const currentSlide = this.container.querySelector(currentDot.getAttribute('href'))
    this.slides.forEach(slide => slide.style.display = 'none')
    currentSlide.style.display = 'block'
  }

  imageLoaded() {
    this.imagesLoadedCounter++
    if (this.imagesLoadedCounter === this.images.length) {
      requestAnimationFrame(() => {
        const maxHeight = this.maxHeight(this.slides)
        this.slides.forEach(slide => slide.style.height = `${maxHeight}px`)
      })
    }
  }

  maxHeight(slides) {
    return Math.max(...[...slides].map(slide => slide.offsetHeight))
  }
}
